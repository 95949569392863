import type { PageFooterData } from 'components/PageFooter/types/PageFooterData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetInternshipsRoutePath } from 'modules/search/routing/helpers/searchGetInternshipsRoutePath';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { searchGetOrgsRoutePath } from 'modules/search/routing/helpers/searchGetOrgsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';
import { postAListingRoute } from 'routing/routes';

export const pageFooterDataPt: PageFooterData = {
  findAJob: {
    title: 'Buscar Emprego',
    links: [
      {
        type: 'jobs',
        title: 'Empregos',
        href: searchGetJobsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'internships',
        title: 'Estágios',
        href: searchGetInternshipsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'organizations',
        title: 'Organizações',
        href: searchGetOrgsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'career-advice',
        title: 'Blog Carreira de Impacto',
        href: '/en/careers',
      },
    ],
  },
  takeAction: {
    title: 'Conectar',
    links: [
      {
        type: 'volops',
        title: 'Voluntariado',
        href: searchGetVolopsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'events',
        title: 'Eventos',
        href: searchGetEventsRoutePath({ lang: 'pt' }),
      },
      {
        type: 'blog',
        title: 'Blog Idealista',
        href: '/pt/blog',
      },
      {
        type: 'idealist-movement',
        title: 'Movimento Idealista',
        href: '/pt/movimento',
      },
      {
        type: 'recipes-for-action',
        title: 'Receitas para Ação',
        href: '/pt/receitas?pq=',
      },
    ],
  },
  postOnIdealist: {
    title: 'Anunciar',
    links: [
      {
        type: 'post-a-listing',
        title: 'Vaga ou Evento',
        href: postAListingRoute.with({}),
      },
      {
        type: 'create-org',
        title: 'Cadastre sua Organização',
        href: '/organizations/create',
      },
      {
        type: 'create-community-group',
        title: 'Cadastre seu Coletivo',
        href: '/organizations/create',
      },
    ],
  },
  aboutUs: {
    title: 'Sobre Nós',
    links: [
      {
        type: 'our-mission',
        title: 'Nosso Propósito',
        href: '/pt/sobre/idealist',
      },
      {
        type: 'our-team',
        title: 'Nossa Equipe',
        href: '/pt/sobre/equipe',
      },
      {
        type: 'donate',
        title: 'Doações para o Idealist',
        href: '/pt/doacao',
      },
    ],
  },
  learnMore: {
    title: 'Saiba mais',
    links: [
      {
        type: 'help-center',
        title: 'Central de Atendimento',
        href: '/pt/ajuda',
      },
      {
        type: 'contact-us',
        title: 'Entre em Contato',
        href: '/pt/sobre/contato',
      },
      {
        type: 'terms-of-service',
        title: 'Termos de Serviço (EN)',
        href: '/en/terms-of-service',
      },
      {
        type: 'privacy-policy',
        title: 'Política de Privacidade (EN)',
        href: '/en/privacy-policy',
      },
      {
        type: 'site-map',
        title: 'Mapa do Site',
        href: '/pt/mapa-do-site',
      },
    ],
  },
};
