import type { IconName } from 'components/Icon/IconName';

import type { PageFooterLinkType } from './types/PageFooterData';

export const pageFooterSocialLinks: Record<
  UserLocale,
  {
    href: string;
    eventName: string;
    iconName: IconName;
  }[]
> = {
  es: [
    {
      href: getText(
        'https://www.linkedin.com/company/idealist-espanol-impacto-social',
      ),
      eventName: 'Follow Idealist',
      iconName: 'social/linkedin',
    },
    {
      href: getText('https://www.instagram.com/idealistas_org/'),
      eventName: 'Follow Idealist',
      iconName: 'social/instagram',
    },
    {
      href: getText('https://www.facebook.com/Idealistasorg'),
      eventName: 'Follow Idealist',
      iconName: 'social/facebook',
    },
    {
      href: getText('https://twitter.com/idealistas_org'),
      eventName: 'Follow Idealist',
      iconName: 'social/x',
    },
  ],
  en: [
    {
      href: getText('https://www.tiktok.com/@joinidealist'),
      eventName: 'Follow Idealist',
      iconName: 'social/tiktok',
    },
    {
      href: getText('https://www.linkedin.com/company/idealist.org'),
      eventName: 'Follow Idealist',
      iconName: 'social/linkedin',
    },
    {
      href: getText('https://instagram.com/joinidealist'),
      eventName: 'Follow Idealist',
      iconName: 'social/instagram',
    },
    {
      href: getText('https://www.facebook.com/Idealist'),
      eventName: 'Follow Idealist',
      iconName: 'social/facebook',
    },
  ],
  pt: [
    {
      href: getText('https://www.linkedin.com/company/plataformaidealist'),
      eventName: 'Follow Idealist',
      iconName: 'social/linkedin',
    },
    {
      href: getText('https://www.instagram.com/canalidealista/'),
      eventName: 'Follow Idealist',
      iconName: 'social/instagram',
    },
    {
      href: getText('https://www.facebook.com/idealist.pt'),
      eventName: 'Follow Idealist',
      iconName: 'social/facebook',
    },
    {
      href: getText('https://www.youtube.com/@Idealismo_pt'),
      eventName: 'Follow Idealist',
      iconName: 'social/youtube',
    },
  ],
};

// [
//   CURRENT_LOCALE() === 'es'
//     ? {
//         href: getText(
//           'https://www.linkedin.com/company/idealist-espanol-impacto-social/',
//         ),
//         eventName: 'Follow Idealist',
//         iconName: 'social/linkedin',
//       }
//     : {
//         href: getText('https://www.tiktok.com/@joinidealist'),
//         eventName: 'Follow Idealist',
//         iconName: 'social/tiktok',
//       },
//   {
//     href: getText('https://www.instagram.com/joinidealist/'),
//     eventName: 'Follow Idealist',
//     iconName: 'social/instagram',
//   },
//   {
//     href: getText('https://www.facebook.com/Idealist'),
//     eventName: 'Follow Idealist',
//     iconName: 'social/facebook',
//   },
// ];

export const pageFooterDataByLinkType: Record<
  PageFooterLinkType,
  { trackClickedName: string }
> = {
  // Find a Job
  jobs: { trackClickedName: 'Jobs' },
  internships: { trackClickedName: 'Internships' },
  organizations: { trackClickedName: 'Organizations' },
  'salary-explorer': { trackClickedName: 'Salary Explorer' },
  'career-advice': { trackClickedName: 'Career Advice' },

  // Take Action
  volops: {
    trackClickedName: 'Volunteer Opportunities',
  },
  events: { trackClickedName: 'Events' },
  petitions: { trackClickedName: 'Sign Petitions' },
  donations: { trackClickedName: 'Give Donations' },
  'idealist-movement': { trackClickedName: 'Idealist Movement' },
  'knowledge-base': { trackClickedName: 'Knowledge Base' },
  'recipes-for-action': { trackClickedName: 'Recipes For Action' },
  blog: { trackClickedName: 'Blog' },

  // Post on Idealist
  'post-a-listing': { trackClickedName: 'Post a Listing' },
  'create-org': { trackClickedName: 'Create Org' },
  'create-community-group': { trackClickedName: 'Create Community Group' },
  'employer-resources': { trackClickedName: 'Employer Resources' },
  'for-grad-schools': { trackClickedName: 'For Grad Schools' },

  // About Us
  'our-mission': { trackClickedName: 'Our Mission' },
  'our-team': { trackClickedName: 'Our Team' },
  'data-insights': { trackClickedName: 'Data & Insights' },
  'our-partners': { trackClickedName: 'Our Partners' },
  donate: { trackClickedName: 'Donate to Idealist' },

  // Learn More
  'help-center': { trackClickedName: 'Help Center' },
  'contact-us': { trackClickedName: 'Contact Us' },
  'terms-of-service': { trackClickedName: 'Terms Of Service' },
  'privacy-policy': { trackClickedName: 'Privacy Policy' },
  'site-map': { trackClickedName: 'Site Map' },
};
