import { css, styled } from 'styled-components';

import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { colors } from 'theme/theme';

const sharedItemCss = css`
  display: inline-flex;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  color: ${colors.lightContentGrey};
`;

export const PageFooterLanguageLinksLink = styled(StyledNativeLinkWithRef)`
  ${sharedItemCss};
`;

export const PageFooterLanguageLinksDisabledText = styled.div`
  opacity: 0.5;
  cursor: auto;
  ${sharedItemCss};
`;
