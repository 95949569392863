import { styled } from 'styled-components';

export const PageFooterLanguageLinksHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  > *:not(:first-child) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -12px;
      height: 16px;
      width: 1px;
      background: currentcolor;
      pointer-events: none;
    }
  }
`;
